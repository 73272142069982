<template>
  <ion-page>
    <ion-header>
      <LoggedInNav
        :title="'Nombre completo'"
        :back="true"
        :backUrl="'/configuracion/datosPersonales'"
      />
    </ion-header>
    <ion-grid class="grid">
      <ion-row>
        <ion-col class="grid__content">
          <h3 class="subtitle-1 ion-text-center">
            Los cambios en tu nombre se verán reflejados en tu cuenta.
          </h3>
        </ion-col>
      </ion-row>
      <ion-row class="w-100">
        <ion-col>
          
          <form @submit.prevent="submitForm" novalidate>
            
            <div class="centerCenter" style="margin-top: 15px">
              <ion-item mode="md">
                <ion-label position="floating" class="labelText"
                  >Nombre completo</ion-label
                >
                <ion-input
                  type="text"
                  name="nombre"
                  v-model="vv.nombre.$model"
                />
              </ion-item>

              <p
                class="error-msg"
                v-if="vv?.nombre?.$errors[0]?.$message === 'Value is required'"
              >
                Nombre requerido
              </p>
            </div>
          </form>
        </ion-col>
      </ion-row>
      <ion-row class="grid__actions">
        <ion-col>
          <div class="centerCenter">
            <ion-button
              @click="submitForm"
              class="btn buttonIniciar"
              expand="block"
              fill="solid"
              :disabled="
                vv.nombre.$errors.length > 0 || vv?.nombre.$dirty == false
              "
              >Guardar</ion-button
            >
          </div>
          
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-page>
</template>

<script>
import { defineComponent } from "vue";
import { reactive, toRef } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import {
  IonPage,
  IonCol,
  IonGrid,
  IonRow,
  IonHeader,
  IonButton,
  modalController,
  IonLabel,
  IonInput,
  IonItem,
} from "@ionic/vue";
import LoggedInNav from "../../../../components/LoggedInNav.vue";
import Modal from "../../../../components/Modals/ModalCambiarEmail.vue";
import AccountService from "../../../../services/account.service";
import { useStore } from "vuex";
export default defineComponent({
  name: "Nombre",
  components: {
    IonPage,
    IonCol,
    IonGrid,
    IonRow,
    IonHeader,
    IonButton,
    LoggedInNav,
    IonLabel,
    IonInput,
    IonItem
  },
  data() {
    return {
      isOpenRef: false,
    };
  },
  setup() {
    const store = useStore();
    const fform = reactive({
      nombre: "",
    });

    const rules = {
      nombre: { required },
    };
    const vv = useVuelidate(rules, {
      nombre: toRef(fform, "nombre"),
    });

    const openModal = async () => {
      const modal = await modalController.create({
        component: Modal,
        mode: "md"
      });
      return modal.present();
    }

    const submitForm = function () {
      vv.value.$touch();
      if (vv.value.$invalid) return 0;
      AccountService.changeName({
        fullName: fform.nombre,
      }).then((data) => {
        if (data === "success") {
          store.commit("User/setFullName", fform.nombre);
          openModal();
        }
      });
    };
    return {
      openModal,
      store,
      submitForm,
      vv,
    };
  }
});
</script>
<style lang="scss" scoped>
.grid {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 38px 14px 35px;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 32px;
  }

  &__actions {
    width: 100%;
    flex: 1 1 auto;
    display: flex;
    align-items: flex-end;
  }
}

.label {
  display: flex;
  align-items: center;

  img {
    margin-right: 20px;
  }

  h5 {
    margin: 0;
  }
}

ion-item {
  border-radius: 10px 10px 0 0;
  border-bottom: 3px solid #b4b4b4;
  --background: white;
  margin-top: 10px;
  --highlight-color-focused: #d4692700;
  width: 100%;
  max-width: 700px;
}

ion-item.item-has-focus {
  border-bottom: 3px solid #d46927;
}

ion-item.item-has-focus > ion-label {
  color: #d46827;
}  
</style>
