<template>
  
 
  <ion-content class="ion-padding success">
    <table>
      <tr>
        <td width="25%" valign="top">
          <div style="padding: 10px">
            <img src="../../../public/assets/icon/success.svg">
          </div>
          
        </td>
        <td>
          <div style="padding: 10px 0 5px 10px">
            <span class="successTitle"> ¡Listo! </span>
          </div>

          <div style="padding: 0px 30px 10px 10px">
            <span class="successContent"> Tus datos fueron actualizados </span>
          </div>
        </td>
        <td width="10%" valign="top">
          <div style="padding-top: 10px; cursor: pointer">
            <img src="../../../public/assets/icon/closeModal.svg" v-on:click="cancel">
          </div>
          
        </td>
      </tr>
      <tr>
        <td width="25%" valign="top">
        </td>
        <td>
          <table>
            <tr>
              <td>
                <div style="padding: 0px 0px 0px 10px">
                  <span class="successContent" style="letter-spacing: 1.5px" v-on:click="goToHome"> IR A PROGRESO </span>
                </div>
              </td>
              <td>
                <div style="padding: 20px 10px 0px 10px">
                </div>
              </td>
            </tr>
          </table>
        </td>
        <td width="10%">

        </td>
      </tr>
    </table>

  </ion-content>
</template>

<script>
import { IonContent, modalController } from '@ionic/vue';
import { defineComponent } from 'vue';
import router from "../../router/index";

export default defineComponent({
  name: 'ModalCambiarEmail',
  data() {
    return {
    }
  },
  components: { IonContent },
  methods: {
    goToHome() {
      const modal =  modalController
      router.replace("/dash/");
      return modal.dismiss();
    },
    cancel() {
      const modal =  modalController;
      router.replace("/configuracion");
      return modal.dismiss();
    },
  }
});
</script>

<style scoped>
table {
  text-align: left;
}
.success {
  --background: #4E833C;
}

.successTitle {
  color: white;
  font-family: "Avenir";
  font-size: 20px;
  letter-spacing: 1px;
}

.successContent {
  color: white;
  font-family: "Avenir";
  font-size: 12px;
  letter-spacing: .75px;
  cursor: pointer;
}


</style>

